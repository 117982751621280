import * as React from "react";
import  {Button} from "react-bootstrap";

export default function EightLoop() {

    const [step, setStep] = React.useState("A");

    const isStep = (selectedstep) => {
        if (step === selectedstep) {
            return false;
        }
        return true;
    }
      
    const handleStep = (step) => {
        setStep(step);
    }


    return (
        <div>
            <p style={{position:"absolute", right: "120px", width:"200px"}} hidden={isStep("A")}><b>Step A</b>: Prototypes situated in everyday life</p>
            <p style={{position:"absolute", right: "120px", width:"200px"}} hidden={isStep("B")}><b>Step B</b>: Collecting data</p>
            <p style={{position:"absolute", right: "120px", width:"200px"}} hidden={isStep("C")}><b>Step C</b>: Towards insights into context, behavior and experience</p>
            <p style={{position:"absolute", right: "120px", width:"200px"}} hidden={isStep("D")}><b>Step D</b>: Design synthesis</p>
            <p style={{position:"absolute", right: "120px", width:"200px"}} hidden={isStep("E")}><b>Step E</b>: Designerly explorations with data as a creative material</p>
            <p style={{position:"absolute", right: "120px", width:"200px"}} hidden={isStep("F")}><b>Step F</b>: Adapting the situated prototypes (remotely)</p>

            <div style={{position: "absolute", top: "450px", right: "50px"}}>
                <Button style={{marginRight: "8px"}} onClick={() => {const steps = ["A","B","C","D","E","F"]; handleStep(steps.indexOf(step)-1 == -1 ? "F" : steps[steps.indexOf(step)-1])}}>Previous step</Button>
                <Button onClick={() => {const steps = ["A","B","C","D","E","F"]; handleStep(steps.indexOf(step)+1 == 6 ? "A" : steps[steps.indexOf(step)+1])}}>Next step</Button>
            </div>
        <svg width="80%" height="80%" viewBox="0 0 500 1041" version="1.1" xmlns="http://www.w3.org/2000/svg">
       <title>DED Loop</title>
       <defs>
           <polygon id="path-1" points="0 0 335.276364 0 335.276364 86.1538647 0 86.1538647"></polygon>
           <polygon id="path-3" points="0 0 335.276364 0 335.276364 86.1538647 0 86.1538647"></polygon>
       </defs>
       <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
           <g id="learning-to-adapt--learning-to-understandEHGBlearning-to-adapt--learning-to-understandEHGB" transform="translate(-49, -29)">
               <g id="Group-5" transform="translate(49, 29)">
                   <g id="Group-120" transform="translate(0, 0.0027)">
                       <g id="Group-5" transform="translate(46.9152, 42.7979)">
                           <path d="M202.668816,405.178198 C90.7379623,405.178198 0,314.47448 0,202.589099 C0,90.7037182 90.7379623,0 202.668816,0 C314.60229,0 405.340252,90.7037182 405.340252,202.589099 C405.340252,314.47448 314.60229,405.178198 202.668816,405.178198 Z" id="Fill-1" fill="#FEFEFE"></path>
                           <path d="M405.340252,202.589099 C405.340252,314.47448 314.60229,405.178198 202.668816,405.178198 C90.7379623,405.178198 0,314.47448 0,202.589099 C0,90.7037182 90.7379623,0 202.668816,0 C314.60229,0 405.340252,90.7037182 405.340252,202.589099 Z" id="Stroke-3" stroke="#FEFEFE" stroke-width="2.47336245"></path>
                       </g>
                       <text id="learning-to" font-family="LucidaGrande, Lucida Grande" font-size="15.720524" font-weight="normal" fill="#547E9A">
                           <tspan x="353.104581" y="247.832105">lea</tspan>
                           <tspan x="375.088751" y="247.832105" letter-spacing="0.0644541463">r</tspan>
                           <tspan x="381.585724" y="247.832105">ning </tspan>
                           <tspan x="420.418796" y="247.832105" letter-spacing="-0.09432314">t</tspan>
                           <tspan x="426.204317" y="247.832105">o</tspan>
                       </text>
                       <text id="understand" font-family="LucidaGrande, Lucida Grande" font-size="15.720524" font-weight="normal" fill="#547E9A">
                           <tspan x="348.592595" y="271.405119">understand</tspan>
                       </text>
                       <g id="Group-94" transform="translate(146.2068, 86.4281)">
                           <path d="M43.6723445,85.859202 C43.6723445,97.9128697 33.896049,107.685193 21.8374824,107.685193 C9.77629551,107.685193 0,97.9128697 0,85.859202 C0,73.8055343 9.77629551,64.0332105 21.8374824,64.0332105 C33.896049,64.0332105 43.6723445,73.8055343 43.6723445,85.859202" id="Fill-6" fill="#547E9A"></path>
                           <path d="M43.6723445,85.859202 C43.6723445,97.9128697 33.896049,107.685193 21.8374824,107.685193 C9.77629551,107.685193 0,97.9128697 0,85.859202 C0,73.8055343 9.77629551,64.0332105 21.8374824,64.0332105 C33.896049,64.0332105 43.6723445,73.8055343 43.6723445,85.859202 Z" id="Stroke-8" stroke="#FEFEFE" stroke-width="0.786026201"></path>
                           <path d="M217.266442,62.7228129 C217.266442,74.7764806 207.490146,84.5488044 195.43158,84.5488044 C183.370393,84.5488044 173.594097,74.7764806 173.594097,62.7228129 C173.594097,50.6691452 183.370393,40.8968215 195.43158,40.8968215 C207.490146,40.8968215 217.266442,50.6691452 217.266442,62.7228129" id="Fill-10" fill="#547E9A"></path>
                           <path d="M217.266442,62.7228129 C217.266442,74.7764806 207.490146,84.5488044 195.43158,84.5488044 C183.370393,84.5488044 173.594097,74.7764806 173.594097,62.7228129 C173.594097,50.6691452 183.370393,40.8968215 195.43158,40.8968215 C207.490146,40.8968215 217.266442,50.6691452 217.266442,62.7228129 Z" id="Stroke-12" stroke="#FEFEFE" stroke-width="0.786026201"></path>
                           <path d="M152.414307,141.299525 C152.414307,153.353193 142.638012,163.125517 130.579445,163.125517 C118.518258,163.125517 108.741963,153.353193 108.741963,141.299525 C108.741963,129.245858 118.518258,119.473534 130.579445,119.473534 C142.638012,119.473534 152.414307,129.245858 152.414307,141.299525" id="Fill-14" fill="#547E9A"></path>
                           <path d="M152.414307,141.299525 C152.414307,153.353193 142.638012,163.125517 130.579445,163.125517 C118.518258,163.125517 108.741963,153.353193 108.741963,141.299525 C108.741963,129.245858 118.518258,119.473534 130.579445,119.473534 C142.638012,119.473534 152.414307,129.245858 152.414307,141.299525 Z" id="Stroke-16" stroke="#FEFEFE" stroke-width="0.786026201"></path>
                           <path d="M191.937163,279.681759 C191.937163,291.735427 182.160868,301.50775 170.102301,301.50775 C158.041114,301.50775 148.264819,291.735427 148.264819,279.681759 C148.264819,267.628091 158.041114,257.855768 170.102301,257.855768 C182.160868,257.855768 191.937163,267.628091 191.937163,279.681759" id="Fill-18" fill="#547E9A"></path>
                           <path d="M191.937163,279.681759 C191.937163,291.735427 182.160868,301.50775 170.102301,301.50775 C158.041114,301.50775 148.264819,291.735427 148.264819,279.681759 C148.264819,267.628091 158.041114,257.855768 170.102301,257.855768 C182.160868,257.855768 191.937163,267.628091 191.937163,279.681759 Z" id="Stroke-20" stroke="#FEFEFE" stroke-width="0.786026201"></path>
                           <path d="M64.8531826,285.793194 C64.8531826,297.846861 55.0768871,307.619185 43.0183205,307.619185 C30.9571337,307.619185 21.1808381,297.846861 21.1808381,285.793194 C21.1808381,273.739526 30.9571337,263.967202 43.0183205,263.967202 C55.0768871,263.967202 64.8531826,273.739526 64.8531826,285.793194" id="Fill-22" fill="#547E9A"></path>
                           <path d="M64.8531826,285.793194 C64.8531826,297.846861 55.0768871,307.619185 43.0183205,307.619185 C30.9571337,307.619185 21.1808381,297.846861 21.1808381,285.793194 C21.1808381,273.739526 30.9571337,263.967202 43.0183205,263.967202 C55.0768871,263.967202 64.8531826,273.739526 64.8531826,285.793194 Z" id="Stroke-24" stroke="#FEFEFE" stroke-width="0.786026201"></path>
                           <path d="M100.22393,199.925348 C100.22393,211.973778 90.4502546,221.743482 78.3969286,221.743482 C66.3409823,221.743482 56.5673071,211.973778 56.5673071,199.925348 C56.5673071,187.876919 66.3409823,178.107215 78.3969286,178.107215 C90.4502546,178.107215 100.22393,187.876919 100.22393,199.925348" id="Fill-26" fill="#E7C055"></path>
                           <path d="M100.22393,199.925348 C100.22393,211.973778 90.4502546,221.743482 78.3969286,221.743482 C66.3409823,221.743482 56.5673071,211.973778 56.5673071,199.925348 C56.5673071,187.876919 66.3409823,178.107215 78.3969286,178.107215 C90.4502546,178.107215 100.22393,187.876919 100.22393,199.925348 Z" id="Stroke-28" stroke="#FEFEFE" stroke-width="0.786026201"></path>
                           <path d="M72.5180499,194.259444 C72.5180499,191.027321 75.1619207,188.384525 78.3953564,188.384525 C81.6287921,188.384525 84.272663,191.027321 84.272663,194.259444 C84.272663,197.491566 81.6287921,200.134362 78.3953564,200.134362 C75.1619207,200.134362 72.5180499,197.533473 72.5180499,194.259444 L72.5180499,194.259444 Z M66.6407433,211.886819 C66.6407433,212.138265 66.8084417,212.305895 67.0599894,212.305895 C67.3115371,212.305895 67.4792355,212.138265 67.4792355,211.886819 L67.4792355,210.626973 C67.4792355,205.883559 70.4611236,201.645655 74.8684484,200.00864 C75.8772594,200.637253 77.0956935,201.017041 78.3953564,201.017041 C79.6976397,201.017041 80.8741491,200.637253 81.9222644,200.00864 C86.3322096,201.645655 89.3114773,205.883559 89.3114773,210.626973 L89.3114773,211.886819 C89.3114773,212.138265 89.4791758,212.305895 89.7307235,212.305895 C89.9848914,212.305895 90.1525899,212.138265 90.1525899,211.886819 L90.1525899,210.626973 C90.1525899,205.715928 87.1707018,201.310394 82.7214524,199.421934 C84.1888138,198.162087 85.1137755,196.315534 85.1137755,194.259444 C85.1137755,190.566338 82.0899629,187.543754 78.3953564,187.543754 C74.70075,187.543754 71.6795576,190.566338 71.6795576,194.259444 C71.6795576,196.315534 72.6018991,198.162087 74.0718808,199.380026 C69.620011,201.310394 66.6407433,205.715928 66.6407433,210.626973 L66.6407433,211.886819 Z" id="Fill-30" fill="#FEFEFE"></path>
                           <path d="M72.5180499,194.259444 C72.5180499,191.027321 75.1619207,188.384525 78.3953564,188.384525 C81.6287921,188.384525 84.272663,191.027321 84.272663,194.259444 C84.272663,197.491566 81.6287921,200.134362 78.3953564,200.134362 C75.1619207,200.134362 72.5180499,197.533473 72.5180499,194.259444 Z M66.6407433,211.886819 C66.6407433,212.138265 66.8084417,212.305895 67.0599894,212.305895 C67.3115371,212.305895 67.4792355,212.138265 67.4792355,211.886819 L67.4792355,210.626973 C67.4792355,205.883559 70.4611236,201.645655 74.8684484,200.00864 C75.8772594,200.637253 77.0956935,201.017041 78.3953564,201.017041 C79.6976397,201.017041 80.8741491,200.637253 81.9222644,200.00864 C86.3322096,201.645655 89.3114773,205.883559 89.3114773,210.626973 L89.3114773,211.886819 C89.3114773,212.138265 89.4791758,212.305895 89.7307235,212.305895 C89.9848914,212.305895 90.1525899,212.138265 90.1525899,211.886819 L90.1525899,210.626973 C90.1525899,205.715928 87.1707018,201.310394 82.7214524,199.421934 C84.1888138,198.162087 85.1137755,196.315534 85.1137755,194.259444 C85.1137755,190.566338 82.0899629,187.543754 78.3953564,187.543754 C74.70075,187.543754 71.6795576,190.566338 71.6795576,194.259444 C71.6795576,196.315534 72.6018991,198.162087 74.0718808,199.380026 C69.620011,201.310394 66.6407433,205.715928 66.6407433,210.626973 L66.6407433,211.886819 Z" id="Stroke-32" stroke="#FEFEFE" stroke-width="1.2"></path>
                           <path d="M121.186236,21.8181338 C121.186236,33.866563 111.412561,43.6362676 99.3592347,43.6362676 C87.3032884,43.6362676 77.5296132,33.866563 77.5296132,21.8181338 C77.5296132,9.76970456 87.3032884,0 99.3592347,0 C111.412561,0 121.186236,9.76970456 121.186236,21.8181338" id="Fill-34" fill="#E7C055"></path>
                           <path d="M121.186236,21.8181338 C121.186236,33.866563 111.412561,43.6362676 99.3592347,43.6362676 C87.3032884,43.6362676 77.5296132,33.866563 77.5296132,21.8181338 C77.5296132,9.76970456 87.3032884,0 99.3592347,0 C111.412561,0 121.186236,9.76970456 121.186236,21.8181338 Z" id="Stroke-36" stroke="#FEFEFE" stroke-width="0.786026201"></path>
                           <path d="M93.480356,16.152229 C93.480356,12.9201069 96.1242268,10.2773101 99.3576625,10.2773101 C102.591098,10.2773101 105.234969,12.9201069 105.234969,16.152229 C105.234969,19.3843511 102.591098,22.0271478 99.3576625,22.0271478 C96.1242268,22.0271478 93.480356,19.4262587 93.480356,16.152229 L93.480356,16.152229 Z M87.6030494,33.7796048 C87.6030494,34.0310503 87.7707478,34.1986806 88.0222955,34.1986806 C88.2738432,34.1986806 88.4415416,34.0310503 88.4415416,33.7796048 L88.4415416,32.5197582 C88.4415416,27.776344 91.4234297,23.5384399 95.8307545,21.9014251 C96.8395655,22.5300388 98.0579996,22.9098262 99.3576625,22.9098262 C100.659946,22.9098262 101.836455,22.5300388 102.884571,21.9014251 C107.294516,23.5384399 110.273783,27.776344 110.273783,32.5197582 L110.273783,33.7796048 C110.273783,34.0310503 110.441482,34.1986806 110.69303,34.1986806 C110.947198,34.1986806 111.114896,34.0310503 111.114896,33.7796048 L111.114896,32.5197582 C111.114896,27.6087136 108.133008,23.2031793 103.683758,21.314719 C105.15112,20.0548724 106.076082,18.2083196 106.076082,16.152229 C106.076082,12.4591235 103.052269,9.4365393 99.3576625,9.4365393 C95.6630561,9.4365393 92.6418637,12.4591235 92.6418637,16.152229 C92.6418637,18.2083196 93.5642052,20.0548724 95.0341869,21.2728114 C90.5823171,23.2031793 87.6030494,27.6087136 87.6030494,32.5197582 L87.6030494,33.7796048 Z" id="Fill-38" fill="#FEFEFE"></path>
                           <path d="M93.480356,16.152229 C93.480356,12.9201069 96.1242268,10.2773101 99.3576625,10.2773101 C102.591098,10.2773101 105.234969,12.9201069 105.234969,16.152229 C105.234969,19.3843511 102.591098,22.0271478 99.3576625,22.0271478 C96.1242268,22.0271478 93.480356,19.4262587 93.480356,16.152229 Z M87.6030494,33.7796048 C87.6030494,34.0310503 87.7707478,34.1986806 88.0222955,34.1986806 C88.2738432,34.1986806 88.4415416,34.0310503 88.4415416,33.7796048 L88.4415416,32.5197582 C88.4415416,27.776344 91.4234297,23.5384399 95.8307545,21.9014251 C96.8395655,22.5300388 98.0579996,22.9098262 99.3576625,22.9098262 C100.659946,22.9098262 101.836455,22.5300388 102.884571,21.9014251 C107.294516,23.5384399 110.273783,27.776344 110.273783,32.5197582 L110.273783,33.7796048 C110.273783,34.0310503 110.441482,34.1986806 110.69303,34.1986806 C110.947198,34.1986806 111.114896,34.0310503 111.114896,33.7796048 L111.114896,32.5197582 C111.114896,27.6087136 108.133008,23.2031793 103.683758,21.314719 C105.15112,20.0548724 106.076082,18.2083196 106.076082,16.152229 C106.076082,12.4591235 103.052269,9.4365393 99.3576625,9.4365393 C95.6630561,9.4365393 92.6418637,12.4591235 92.6418637,16.152229 C92.6418637,18.2083196 93.5642052,20.0548724 95.0341869,21.2728114 C90.5823171,23.2031793 87.6030494,27.6087136 87.6030494,32.5197582 L87.6030494,33.7796048 Z" id="Stroke-40" stroke="#FEFEFE" stroke-width="1.2"></path>
                           <line x1="120.456486" y1="103.104171" x2="108.602302" y2="61.4113676" id="Stroke-42" stroke="#E7C055" stroke-width="1.51179039"></line>
                           <polygon id="Fill-44" fill="#E7C055" points="123.372866 113.361051 125.170384 97.2292523 120.239002 102.336739 113.355504 100.587097"></polygon>
                           <polygon id="Fill-46" fill="#E7C055" points="105.685921 51.1544874 115.703283 63.9284416 108.819785 62.1788001 103.888403 67.2862864"></polygon>
                           <line x1="45.5482088" y1="55.8759001" x2="63.6334384" y2="39.8855392" id="Stroke-48" stroke="#E7C055" stroke-width="1.51179039"></line>
                           <polygon id="Fill-50" fill="#E7C055" points="37.5584259 62.9409942 52.8871122 57.5846817 46.1451105 55.3478646 44.7484969 48.3885871"></polygon>
                           <polygon id="Fill-52" fill="#E7C055" points="71.6221733 32.8199212 64.4321023 47.3749475 63.0354887 40.4130508 56.2961072 38.178853"></polygon>
                           <line x1="46.0743627" y1="96.9909029" x2="97.2721751" y2="125.501153" id="Stroke-54" stroke="#547E9A" stroke-width="1.43842795"></line>
                           <polygon id="Fill-56" fill="#547E9A" points="106.146567 130.444676 90.7942985 128.587647 96.6087181 125.132891 96.4855646 118.372674"></polygon>
                           <line x1="61.4161505" y1="80.8465316" x2="157.161484" y2="70.5739361" id="Stroke-58" stroke="#547E9A" stroke-width="1.43842795"></line>
                           <polygon id="Fill-60" fill="#547E9A" points="51.3149393 81.9303664 66.174594 86.2180357 62.1707935 80.7648118 64.9247165 74.5913015"></polygon>
                           <polygon id="Fill-62" fill="#547E9A" points="167.262695 69.4890536 153.652918 76.8307378 156.406841 70.6546082 152.40304 65.2040036"></polygon>
                           <line x1="39.7877671" y1="119.584589" x2="62.1859912" y2="163.451348" id="Stroke-64" stroke="#E7C055" stroke-width="1.51179039"></line>
                           <polygon id="Fill-66" fill="#E7C055" points="34.9381376 110.087022 36.3006875 126.260728 40.149891 120.294137 47.240391 120.679162"></polygon>
                           <polygon id="Fill-68" fill="#E7C055" points="67.0366688 172.948391 54.7317952 162.356251 61.8249155 162.741277 65.6714987 156.774685"></polygon>
                           <line x1="86.9849234" y1="65.0431832" x2="77.8768014" y2="160.369831" id="Stroke-70" stroke="#E7C055" stroke-width="1.51179039"></line>
                           <polygon id="Fill-72" fill="#E7C055" points="87.9989749 54.4285171 80.455165 68.802817 86.908935 65.8378557 92.6814301 69.9709908"></polygon>
                           <polygon id="Fill-74" fill="#E7C055" points="76.8627498 170.983974 72.1776744 155.4415 77.9527897 159.574635 84.4039394 156.609674"></polygon>
                           <line x1="160.050089" y1="243.024937" x2="143.031317" y2="179.485188" id="Stroke-76" stroke="#547E9A" stroke-width="1.43842795"></line>
                           <polygon id="Fill-78" fill="#547E9A" points="162.67719 252.834716 164.626685 237.499161 159.85252 242.29234 153.325382 240.524364"></polygon>
                           <polygon id="Fill-80" fill="#547E9A" points="140.40474 169.674362 149.756549 181.984713 143.229411 180.216737 138.457866 185.009917"></polygon>
                           <line x1="53.5366817" y1="249.226735" x2="61.539042" y2="233.524489" id="Stroke-82" stroke="#E7C055" stroke-width="1.51179039"></line>
                           <polygon id="Fill-84" fill="#E7C055" points="48.694651 258.727969 60.9916638 248.127971 53.8985435 248.515616 50.0467197 242.554262"></polygon>
                           <polygon id="Fill-86" fill="#E7C055" points="66.3815968 224.023254 65.0321483 240.19958 61.1777043 234.235608 54.0872042 234.625872"></polygon>
                           <line x1="132.893946" y1="277.164947" x2="83.063924" y2="280.889483" id="Stroke-88" stroke="#547E9A" stroke-width="1.43842795"></line>
                           <polygon id="Fill-90" fill="#547E9A" points="143.025028 276.407729 128.31211 271.643361 132.13511 277.222308 129.184666 283.304145"></polygon>
                           <polygon id="Fill-92" fill="#547E9A" points="72.9323174 281.646177 86.77268 274.747141 83.8196152 280.831598 87.645236 286.407926"></polygon>
                       </g>
                       <text id="learning-to" font-family="LucidaGrande, Lucida Grande" font-size="15.720524" font-weight="normal" fill="#547E9A">
                           <tspan x="75.4769164" y="247.830796">lea</tspan>
                           <tspan x="97.4610867" y="247.830796" letter-spacing="0.0644541463">r</tspan>
                           <tspan x="103.95806" y="247.830796">ning </tspan>
                           <tspan x="142.791132" y="247.830796" letter-spacing="-0.09432314">t</tspan>
                           <tspan x="148.576653" y="247.830796">o</tspan>
                       </text>
                       <text id="adapt-" font-family="LucidaGrande, Lucida Grande" font-size="15.720524" font-weight="normal" fill="#547E9A">
                           <tspan x="75.4755768" y="271.40381">adapt </tspan>
                       </text>
                       <g id="Group-119">
                           <g id="Group-97" transform="translate(84.6382, 954.8461)">
                               <mask id="mask-2" fill="white">
                                   <use href="#path-1"></use>
                               </mask>
                               <g id="Clip-96"></g>
                               <path d="M315.888852,0 L335.276364,19.3822557 C290.644994,60.7895639 230.915523,86.1541266 165.363772,86.1541266 C102.015683,86.1541266 44.104692,62.4658671 0,23.5022947 L19.4110955,4.10170438 C58.5162775,38.1228016 109.572594,58.7491886 165.363772,58.7491886 C223.358612,58.7491886 276.233409,36.4543561 315.888852,0 Z" id="Fill-95" fill-opacity="0.2" fill={!isStep("D") ?"#DBB65C" : "#3F3C3A"} mask="url(#mask-2)" onClick={() => {handleStep("D")}}></path>
                           </g>
                           <path d="M161.172359,534.202993 L201.773726,534.202993 L194.659643,540.711764 L111.245386,617.046421 L110.632239,617.606935 L109.987648,618.130779 C57.5137552,660.60935 27.4171242,723.654065 27.4171242,791.101696 C27.4171242,848.177201 49.0371227,900.297134 84.5105852,939.724309 L65.10211,959.124899 C24.6763027,914.713341 0.0010481153,855.733661 0.0010481153,791.101696 C0.0010481153,715.280407 33.7818044,644.556128 92.7330497,596.833871 L161.172359,534.202993 Z" id="Fill-98" fill-opacity="0.2" fill={!isStep("C") ?"#DBB65C" : "#3F3C3A"} onClick={() => {handleStep("C")}}></path>
                           <path d="M338.786765,534.202469 L406.513355,596.228306 C465.946734,643.955802 500,714.894857 500,791.101172 C500,853.53037 476.980768,910.684451 438.981347,954.530257 L419.536188,935.095617 C452.598985,896.257767 472.583924,845.97129 472.583924,791.101172 C472.583924,723.315662 442.243606,660.074504 389.342606,617.590695 L388.65085,617.03542 L387.993158,616.432998 L305.304721,540.706002 L298.20112,534.202469 L338.786765,534.202469 Z" id="Fill-100" fill-opacity="0.2" fill={!isStep("E") ?"#DBB65C" : "#3F3C3A"} onClick={() => {handleStep("E")}}></path>
                            <g id="Group-104" transform="translate(84.6371, 0)">
                               <mask id="mask-4" fill="white">
                                    <use href="#path-3"></use> 
                               </mask>
                               <g id="Clip-103"></g>
                               <path d="M315.888852,86.1538647 L335.276364,66.771609 C290.644994,25.3643008 230.915523,-0.000261922374 165.363772,-0.000261922374 C102.015683,-0.000261922374 44.104692,23.6879976 0,62.65157 L19.4110955,82.0521603 C58.5162775,48.0310631 109.572594,27.4046761 165.363772,27.4046761 C223.358612,27.4046761 276.233409,49.6995086 315.888852,86.1538647 Z" id="Fill-102" fill-opacity="0.2" fill={!isStep("A") ?"#DBB65C" : "#3F3C3A"} mask="url(#mask-4)" onClick={() => {handleStep("A")}}></path>
                           </g>
                           <path d="M161.171311,506.797007 L201.772677,506.797007 L194.658595,500.288236 L111.244338,423.953579 L110.631191,423.393065 L109.9866,422.869221 C57.5127071,380.39065 27.4160761,317.345935 27.4160761,249.898304 C27.4160761,192.822799 49.0360746,140.702866 84.5095371,101.275691 L65.1010619,81.8751008 C24.6752546,126.286659 0,185.266339 0,249.898304 C0,325.719593 33.7807563,396.443872 92.7320016,444.166129 L161.171311,506.797007 Z" id="Fill-105" fill-opacity="0.2" fill={!isStep("F") ?"#DBB65C" : "#3F3C3A"} onClick={() => {handleStep("F")}}></path>
                           <path d="M338.785716,506.797531 L406.512307,444.771694 C465.945686,397.044198 499.998952,326.105143 499.998952,249.898828 C499.998952,187.46963 476.979719,130.315549 438.980299,86.4697431 L419.53514,105.904383 C452.597937,144.742233 472.582876,195.02871 472.582876,249.898828 C472.582876,317.684338 442.242558,380.925496 389.341558,423.409305 L388.649802,423.96458 L387.99211,424.567002 L305.303673,500.293998 L298.200072,506.797531 L338.785716,506.797531 Z" id="Fill-107" fill-opacity="0.2" fill={!isStep("B") ?"#DBB65C" : "#3F3C3A"} onClick={() => {handleStep("B")}}></path>
                           <path d="M447.319366,768.824936 C436.002341,669.448968 353.667644,595.408751 256.698636,591.542777" id="Stroke-109" stroke="#3F3C3A" stroke-width="4.37292576" stroke-dasharray="13.11877729257642,13.11877729257642"></path>
                           <polygon id="Fill-111" fill="#3F3C3A" points="225.839501 592.684759 268.864634 573.781821 259.00973 591.815176 269.797457 609.306353"></polygon>
                           <path d="M217.300768,593.839574 C112.130258,610.338065 37.6904884,706.85646 49.5682551,813.563635" id="Stroke-113" stroke="#3F3C3A" stroke-width="4.37292576" stroke-dasharray="13.11877729257642"></path>
                           <path d="M249.134912,58.5490799 C143.605422,58.5490799 57.7490571,144.367946 57.7490571,249.854563 C57.7490571,308.370641 84.0200672,362.895021 129.825326,399.448908 L130.273396,399.833934 L249.132292,508.605057 L253.916938,504.220477 L367.543118,400.163956 L367.792046,399.967514 C414.01131,363.411008 440.518146,308.695424 440.518146,249.854563 C440.518146,144.367946 354.664401,58.5490799 249.134912,58.5490799 M435.754462,833.679535 C438.91715,819.784553 440.518146,805.444303 440.518146,791.062146 C440.518146,732.543449 414.247136,678.019068 368.441877,641.467801 L367.993808,641.085394 L249.137532,532.311651 L130.724085,640.752753 L130.475158,640.951814 C84.2558932,677.5057 57.7490571,732.218665 57.7490571,791.062146 C57.7490571,896.548763 143.602802,982.370248 249.132292,982.370248 C317.149734,982.370248 378.681964,946.966201 412.965815,890.102853 L422.317624,891.103397 L427.704937,898.235543 C411.354338,926.850562 393.730279,945.172032 366.966655,963.480406 C332.184948,987.276054 291.439466,999.853567 249.132292,999.853567 C133.960141,999.853567 40.2586329,906.190125 40.2586329,791.062146 C40.2586329,726.948787 69.0896647,667.330016 119.372996,627.436619 L236.185447,520.462283 L118.67862,412.927433 C68.8407373,373.039275 40.2586329,313.616946 40.2586329,249.854563 C40.2586329,134.726583 133.960141,41.0631422 249.134912,41.0631422 C364.309682,41.0631422 458.00857,134.726583 458.00857,249.854563 C458.00857,313.967922 429.177539,373.586693 378.894207,413.480089 L262.084376,520.457045 L379.588583,627.991895 C429.429086,667.877434 458.00857,727.297144 458.00857,791.062146 C458.00857,806.748677 456.260838,822.390681 452.809919,837.555986" id="Fill-115" fill="#3F3C3A"></path>
                           <path d="M249.134912,58.5490799 C143.605422,58.5490799 57.7490571,144.367946 57.7490571,249.854563 C57.7490571,308.370641 84.0200672,362.895021 129.825326,399.448908 L130.273396,399.833934 L249.132292,508.605057 L253.916938,504.220477 L367.543118,400.163956 L367.792046,399.967514 C414.01131,363.411008 440.518146,308.695424 440.518146,249.854563 C440.518146,144.367946 354.664401,58.5490799 249.134912,58.5490799 Z M435.754462,833.679535 C438.91715,819.784553 440.518146,805.444303 440.518146,791.062146 C440.518146,732.543449 414.247136,678.019068 368.441877,641.467801 L367.993808,641.085394 L249.137532,532.311651 L130.724085,640.752753 L130.475158,640.951814 C84.2558932,677.5057 57.7490571,732.218665 57.7490571,791.062146 C57.7490571,896.548763 143.602802,982.370248 249.132292,982.370248 C317.149734,982.370248 378.681964,946.966201 412.965815,890.102853 L422.317624,891.103397 L427.704937,898.235543 C411.354338,926.850562 393.730279,945.172032 366.966655,963.480406 C332.184948,987.276054 291.439466,999.853567 249.132292,999.853567 C133.960141,999.853567 40.2586329,906.190125 40.2586329,791.062146 C40.2586329,726.948787 69.0896647,667.330016 119.372996,627.436619 L236.185447,520.462283 L118.67862,412.927433 C68.8407373,373.039275 40.2586329,313.616946 40.2586329,249.854563 C40.2586329,134.726583 133.960141,41.0631422 249.134912,41.0631422 C364.309682,41.0631422 458.00857,134.726583 458.00857,249.854563 C458.00857,313.967922 429.177539,373.586693 378.894207,413.480089 L262.084376,520.457045 L379.588583,627.991895 C429.429086,667.877434 458.00857,727.297144 458.00857,791.062146 C458.00857,806.748677 456.260838,822.390681 452.809919,837.555986 L435.754462,833.679535 Z" id="Stroke-117" stroke="#FEFEFE" stroke-width="2.26637555"></path>
                       </g>
                       <text id="B" font-family="LucidaGrande, Lucida Grande" font-size="17.1335371" font-weight="normal" fill="#1D1D1B">
                           <tspan x="481.682736" y="249.588981">B</tspan>
                       </text>
                       <text id="G" font-family="LucidaGrande, Lucida Grande" font-size="17.1335371" font-weight="normal" fill="#1D1D1B">
                           <tspan x="243.594073" y="485.525663">G</tspan>
                       </text>
                       <text id="H" font-family="LucidaGrande, Lucida Grande" font-size="17.1335371" font-weight="normal" fill="#1D1D1B">
                           <tspan x="243.885374" y="560.323158">H</tspan>
                       </text>
                       <text id="E" font-family="LucidaGrande, Lucida Grande" font-size="17.1335371" font-weight="normal" fill="#1D1D1B">
                           <tspan x="482.025408" y="791.292752">E</tspan>
                       </text>
                   </g>
                   <path d="M249.636698,451.061377 C287.475844,449.647041 325.566481,438.07306 347.307486,422.819186 C397.904637,392.363821 434.588488,339.907677 442.678164,276.794251 C456.725069,167.209417 379.257515,66.9880093 269.648692,52.9441785 C160.039869,38.9003476 59.7965226,116.353568 45.7496176,225.935783 C37.6992373,288.74277 59.7100721,348.469647 100.601184,390.663999 C131.057976,422.091065 171.991003,443.790643 218.77909,449.785856" id="Stroke-1" stroke="#3F3C3A" stroke-width="4.37292576" stroke-dasharray="13.11877729257642,13.11877729257642"></path>
                   <polygon id="Fill-3" fill="#3F3C3A" points="218.780138 449.784808 261.712007 468.878341 251.940475 450.801034 262.801808 433.360178"></polygon>
                   <text id="A" font-family="LucidaGrande, Lucida Grande" font-size="17.1335371" font-weight="normal" fill="#1D1D1B">
                       <tspan x="248.471616" y="21">A</tspan>
                   </text>
                   <text id="F" font-family="LucidaGrande, Lucida Grande" font-size="17.1335371" font-weight="normal" fill="#1D1D1B">
                       <tspan x="13.1004367" y="277.413714">F</tspan>
                   </text>
                   <text id="C" font-family="LucidaGrande, Lucida Grande" font-size="17.1335371" font-weight="normal" fill="#1D1D1B">
                       <tspan x="8.1004367" y="799.769134">C</tspan>
                   </text>
                   <text id="D" font-family="LucidaGrande, Lucida Grande" font-size="17.1335371" font-weight="normal" fill="#1D1D1B">
                       <tspan x="250" y="1033.33382">D</tspan>
                   </text>
                   <polygon id="Fill-1" fill="#3F3C3A" points="432.587094 861.354332 400.218341 895.277743 420.469052 892.18999 433.187773 908.285555"></polygon>
               </g>
           </g>
       </g>
   </svg>
   </div>
  );
}