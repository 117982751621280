import React from "react"
import Header from '../components/header'
import { Container, Row, Col } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import { withPrefix } from "gatsby";
// import Loop from '../assets/eightloop.svg';
import EightLoop from "../assets/eightloop";

export default function Home() {
  return (
    <>
    
    <Header activePage="Home" />
    <Container className="fluid">
      {/* <img width={"99%"} src={withPrefix("../header.png")} /> */}
    <h1 style={{paddingTop: "15px"}}>Data-enabled Design</h1>
    <Row>
        <Col xs={8} style={{textAlign: "justify", paddingRight: "50px" }}>
          {/* <h2>the book</h2> */}
          <p>Data-enabled design (DED) is a relatively young approach, developed from needs to innovate based on reliable, deep contextual insights and to design adaptive systems that can address end-user needs in a personalized way. One key aspect of the approach is that data serves the design process in an active form, that it can be collected flexibly from the context and from real end-users through appropriate creative means. In this sense, data-enabled design aims to innovate on what we can know from a context and how this knowledge and insight can benefit the design process effectively.</p>

          <p>In Data-enabled Design, designers and design researchers use data as a creative design material. The goal of data-enabled design projects is to design complex products and services that are embedded in so-called “intelligent ecosystems”, i.e., dynamic compositions of interrelated products, services and people. These systems are meant to understand users within their context by collecting and processing data and adapting their function and experience accordingly.</p>
         
          <h3>Two explorations</h3>
          <p>The data-enabled design process supports two kinds of explorations: a research-oriented contextual exploration and a solution-oriented informed exploration. We call them explorations as they are a collection of design activities composed of the six steps of the data-enabled design loop. While the loop might seem structured and linear, in reality, design teams applying DED carry out activities based on the kind of explorations that are appropriate in a given phase of the design process. For instance, different users might be involved in different user tests, or the same users might go through multiple explorations during one user test. These explorations might happen in parallel when the designers wish to explore multiple elements of the system at the same time. In this way, contextual and informed explorations can sometimes happen simultaneously. Below, we describe the characteristics of the two kinds of explorations in more detail.</p>

          <p>The goal of a <i>contextual exploration</i> is to gain an understanding of the everyday con- text that the team is designing for, focused on understanding values and motivations of users in their context. These contextual explorations have a focus on collecting research data, which is data that informs the design research team about the intri- cacies of the context, and which provide room for identifying design opportunities. During contextual explorations, participants provide data and receive basic feedback in return. One of the outcomes of these explorations is a data strategy that informs the design research team on what data (not) to collect from the context.</p>

          <p>The second kind of exploration, the <i>informed exploration</i>, has a stronger focus on design and on bringing interventions into the context. A new group of participants might be recruited for this kind of exploration, to make sure that assumptions from the contextual explorations can be evaluated against a fresh pool of participants and their contexts. These informed explorations have a stronger focus on so-called solution data. Solution data is data that plays a role in facilitating interactions between users and the system, e.g., by being presented to them for reflection and feedback or by triggering system interactions.</p>

          <p>Find more information on the concepts page.</p>

        </Col>
        <Col xs={4}>
          {/* <Loop style={{width: "350px", marginTop: "-200px"}}/> */}
          <EightLoop/>
          
          {/* <h2>Important dates</h2>
          <ul>
            <li><s>21 March: Early-bird registration</s></li>
            <li><s>22 March - 29 April: Regular registration</s></li>
            <li>30 April and later: Late registration</li>
            <li>30 April- 6 May: CHI 2022
              <ul>
                <li>4 May: Course Day</li>
              </ul>
            </li>
          </ul> */}

          {/* <h2>Planned sessions</h2>
          <p>The course will be divided into three 75 minutes sessions. The covered topics will be the following:</p>
          <h4>Session 1 - 09:00-10:15</h4>
          <ul>
            <li>09:00 - 09:15: Welcome and short round of introductions</li>
            <li>09:15 - 09:30: Introduction to Data-enabled Design method</li>
            <li>09:30 - 09:45: Co-responsibility case study</li>
            <li>09:45-10:15: Receive probes, brainstorm where to place them, check Data Foundry connection</li>
          </ul>
          <h4>Session 2 - 14:15-15:30</h4>
          <ul>
            <li>14:15 - 14:30: Asking questions, from data to insights</li>
            <li>14:30 - 15:00: Discussion about collected data, visualization</li>
            <li>15:00 - 15:30: Data-enabled Interviews with other teams</li>
          </ul>
          <h4>Session 3 - 16:15-17:30</h4>
          <ul>
            <li>16:15 - 16:30: Informed step</li>
            <li>16:30 - 17:00: What to design based on collected data?</li>
            <li>17:00 - 17:30: Pitches, recap, end of day</li>
          </ul> */}

        </Col>
      </Row>

      {/* <p>Our website is currently under construction. However we invite you to  <a rel="noopener noreferrer" href={withPrefix('./submission.pdf')} target="_blank">read our submission</a> that got this course accepted for the conference</p> */}
      







      
  

      


      {/* <Row>
        <Col>
          <h2>Background</h2>
          <p style={{textAlign: 'justify'}}>Although data plays an increasingly important role in the intelligent products, services and systems we design for, it is not yet so present in contemporary design processes. Established data design methods, as data-driven design (King.2017), emphasize the power of data to evaluate and validate design decisions. Data-enabled design (DED) (vanKollenburg:2019wn) provides a more designerly perspective, where data is used as creative material to inspire and inform new design interventions. DED is characterized as a quick and iterative approach that sets out to design, deploy and remotely adapt prototypes while they are situated in the daily life of people. Design researchers and practitioners use DED to acquire in-depth contextual insights of both quantitative and qualitative nature, and inform their subsequent, iterative design steps.<br/>
          Participants of this course will learn how to use data as creative material for design, both at conceptual and at hands-on prototyping level. We will show participants how to setup and execute contextual design explorations that are open and flexible so they allow for a variety of contexts, scenarios and situations. Participants of this course will collect contextual, behavioral and experiential data using both sensor data and qualitative data in the form of data-enabled interviews and self reports. During the course the instructors and participants will together analyse the collected data to discover rich and nuanced personal stories and design opportunities.<br/>This course not only serves as a platform to teach the DED methodology, but is also meant as a place where different designers working with data can connect with each other and share their experiences around designing with data. The assignments will be done in groups of three or four participants, to stimulate an exchange of knowledge, and to allow participants to familiarise themselves with methods that others are using to design with data.</p>
        </Col>
        <Col>
          <h2>Logistics</h2>
          <div>
            <p>
              <b>Duration of the course:</b> 3x 75 minutes
            </p>
            <p>
              <b>Planned days:</b> 
              <ul>
                <li>Monday - 2x 75 minutes</li>
                <li>Thursday - 1x 75 minutes</li>
              </ul>
            </p>
            
          </div>

          <h2>Learning outcomes</h2>
        </Col>
      </Row> */}

    </Container>
    </>
  )
}
